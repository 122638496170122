.footer hr {
    border: .8px solid whitesmoke;
    margin: 15px 0;
}

.footer .strong-div-dropdown-menu-div strong {
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
}
.body > div > div {
    transition: all 100ms ease-in-out;
    user-select: none;
}
.body > div > div:hover, .shadow-class {
    box-shadow: 0 4px 10px rgb(128, 128, 128, .4);
}
.body > div > div:nth-child(1) {
    border: 1.4px solid rgba(128, 128, 128, 0.6);
}
.explore-btn {
    transition: all 200ms ease-in-out;
    color: #0132F3;
    background-color: white;
}
.explore-btn:hover {
    background: #0132F3;
    color: white;
}
.section_1 div {
    display: grid;
    gap: 20px;
    width: 93%;
    margin: 0 auto 7px auto;
}
.section_1 p {
    margin: 15px 0 20px 0;
    font-size: 15px;
    color: rgb(0,0,0,.76);
}
.section_1 div li a {
    text-decoration: none;
    color: rgb(1, 50, 243, .85);
}
.section_1 div li a:hover {
    text-decoration: underline;
}
.section3 p{
    margin: 20px 0;
    font-size: 15px;
}
.section3 ul {
    width: 87%;
    margin: 0 auto 10px auto;
    display: grid;
    gap: 15px;
}
.section4 li {
    margin: 0 auto 14px auto;
    width: 90%;
}
.section4 li a {
    text-decoration: none;
    color: black;
}
.section4 p:nth-child(5) {
    margin: 25px 0;
}
.section4 p:nth-child(6) a {
    color: #0132F3;
}
a {
    text-decoration: none; /* Remove the underline by default */
  }
  
  a:hover {
    text-decoration: underline; /* Add underline on hover */
  }
  